import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Accessibility = () => {
	return (
		<Layout>
			<SEO
				title="BMW Performance Driving School | Accessibility"
				description="BMW is committed to providing a website that is accessible for people with disabilities. To meet this commitment, we are continually improving the user experience to ensure we provide equal access to all of our users."
			/>
			<div className="accessibilitypage">
				<h2 className="bold">BMW ACCESSIBILITY STATEMENT</h2>
				<p>
					<span className="blue-text">BMWPerformanceCenter.com</span> is
					committed to providing a website that is accessible for people with
					disabilities. To meet this commitment, we are continually improving
					the user experience to ensure we provide equal access to all of our
					users.
				</p>
				<h3 className="mt-6"> Measures to support accessibility</h3>
				<p>
					<span className="blue-text">BMWPerformanceCenter.com</span> takes the
					following measures to assess and ensure accessibility of our website:
				</p>
				<ul>
					<li>Provide continual accessibility training for our staff;</li>
					<li>Assign clear accessibility targets and responsibilities;</li>
					<li>Employ formal accessibility quality assurance methods.</li>
				</ul>
				<p>
					Whenever possible,{" "}
					<span className="blue-text">BMWPerformanceCenter.com</span> will
					adhere to the Web Content Accessibility Guidelines (WCAG) 2.1 levels A
					and AA, which defines requirements for designers and developers to
					improve accessibility for people with disabilities.
				</p>
				<h3 className="mt-6">
					Compatibility with browsers and assistive technology
				</h3>
				<p>
					<span className="blue-text">BMWPerformanceCenter.com</span> uses the
					following browsers and assistive technology for conformance with
					accessibility standards:
				</p>
				<ul>
					<li>
						MacOS using the latest Safari browser version and VoiceOver as the
						assistive technology
					</li>
					<li>
						Windows using the latest Firefox browser version and NVDA as the
						assistive technology
					</li>
				</ul>
				<h3>Technical specifications</h3>
				<p>
					Conformance with accessibility standards for{" "}
					<span className="blue-text">BMWPerformanceCenter.com</span>{" "}
					relies on the following technologies:
				</p>
				<ul>
					<li>HTML</li>
					<li>WAI-ARIA</li>
					<li>CSS</li>
				</ul>
				<h3>Implementation tools</h3>
				<p>
					The following tools have been utilized by{" "}
					<span className="blue-text">BMWPerformanceCenter.com</span> to
					implement and ensure the accessibility of our website:
				</p>
				<ul>
					<li> Standards for browsers</li>
					<li> Color contrast checker</li>
					<li> Keyboard navigation</li>
					<li> Screen reader compatibility</li>
				</ul>
				<h3>Limitations and alternatives</h3>
				<p>
					Despite our best efforts to ensure accessibility of{" "}
					<span className="blue-text">BMWPerformanceCenter.com</span>, there may
					be some limitations. Below is a description of known limitations.
				</p>
				<ul>
					<li>
						<span className="bold">
							Alternative text for decorative elements:
						</span>{" "}
						Though we do provide textual alternative for most of our non-text
						content, we omit in instances where it results in duplicative
						content.
					</li>
					<li>
						<span className="bold">Captions and/or transcripts for media:</span>{" "}
						For videos and live audio that do not have spoken text, we do not
						provide captions and a transcript.
					</li>
					<li>
						<span className="bold">Carousels: </span>{" "}
						<span className="blue-text">BMWPerformanceCenter.com's</span> usage
						of carousels incorporate elements like alternative text and manual
						scrolling for improved accessibility, but some users might
						experience technical limitations.
					</li>
					<li>
						<span className="bold">Font styling:</span> Headings and text
						legibility may be compromised to adhere to corporate branding. This
						includes headings formatted in all caps and text placed over media.
					</li>
					<li>
						<span className="bold">Font size:</span> To increase legibility,
						enlarge content by using your native browser functionality.
					</li>
				</ul>
				<p>
					Please note that{" "}
					<span className="blue-text">BMWPerformanceCenter.com</span> is not
					responsible for the accessibility of the content on any third-party
					website, owned and operated by an independent party over which{" "}
					<span className="blue-text">BMWPerformanceCenter.com</span>{"  "}
					has no control. This includes, but is not limited to, dealer websites
					affiliated with BMW.
				</p>
				<h3 className="mt-6">Feedback</h3>
				<p>
					We welcome your feedback relating to improving the accessibility of
					our website. Please don't hesitate to contact us at:
				</p>
				<ul>
					<li className="bold"> Phone: 800-831-1117</li>
					<li className="bold"> Email: customer.relations@bmwusa.com</li>
				</ul>
			</div>
		</Layout>
	);
};

export default Accessibility;
